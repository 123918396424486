// custom animation

.custom-btn{
  transition: .35s;
}

.parent{
  .level1 {
    z-index: 2;
    transform: translateX(85px) translateY(-5px) scale(1.05);
  }

  .level2 {
    z-index: 3;
    transform: translateX(35px) translateY(-3px) scale(1.25);
  }

  .level3 {
    z-index: 4;
    transform: translate(0) scale(1.65);
  }

  .level4 {
    z-index: 3;
    transform: translateX(-35px) translateY(-3px) scale(1.25);
  }

  .level5 {
    z-index: 2;
    transform: translateX(-85px) translateY(-5px) scale(1.05);
  }
}
