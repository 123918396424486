.slider-track1 {
  animation: scroll1 55s linear infinite;
}

.slider-track2 {
  animation: scroll2 55s linear infinite;
}

@keyframes scroll1 {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(calc(-250px * 5));
  }
}

@keyframes scroll2 {
  0%,
  100% {
    transform: translateX(calc(-250px * 5));
  }
  50% {
    transform: translateX(0);
  }
}
