@import "./main-animation.scss";
@import "custom-animation";
@import "tabCompany.scss";

.navbar {
  .navbar--link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: white;
    transition: 0.2s;

    &:active {
      background: #171717;
    }
  }

  .active {
    color: #0080dd;

    .nav-icon {
      background: #353535;

      svg > path {
        transition: 0.3s;
        stroke: #0080dd;
      }
    }
  }
}

#member-slider > .slick-slider {
  .slick-next,
  .slick-prev {
    display: none !important;
  }

  .slick-dots {
    li {
      margin: 0 3px;
    }

    li > button {
      width: 6px;
      height: 6px;
      background: #d9d9d9;
      border-radius: 50%;
      opacity: 0.2;
      margin: 0 3px;

      &:before {
        display: none;
      }
    }

    .slick-active > button {
      opacity: 1;
    }
  }
}
