/* стиль для точек */
.company_slides {
  .slick-next,
  .slick-prev {
    display: none !important;
    opacity: 0;
  }

  .slick-dots li button {
    width: 2.5px; /* Размер точек */
    height: 2.5px;
    border-radius: 50%; /* Делаем точки круглыми */
    background-color: #757575; /* Цвет точек */
    &::before {
      display: none;
    }
  }

  .slick-dots li.slick-active button {
    background-color: #d9d9d9; /* Цвет активной точки */
  }
}

.video__youtube {
  /* Стили для адаптивного iframe */
  .responsive-iframe {
    // position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 280px;
    border-radius: 12px;
    @media (max-width: 532px) {
      min-height: 240px;
    }
    @media (max-width: 475px) {
      min-height: 220px;
    }
    @media (max-width: 435px) {
      min-height: 200px;
    }
    @media (max-width: 395px) {
      min-height: 170px;
    }
  }
}
