@import "feateures.scss";

.nfc-icon-logo {
  @media (min-width: 768px) {
    width: 87px;
    height: 41px;
  }
  @media (max-width: 768px) {
    width: 67px;
    height: 21px;
  }
}

.video__hero {
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    color: rgba(0, 0, 0, 0.5);
  }
}

.leaverequest {
  &::after {
    content: "";
    position: absolute;
    background: #303030;
    top: -80px;
    right: 45px;
    width: 100px;
    height: 100px;
    transform: rotate(75deg);
    border-radius: 45px;
    transition: ease-in 2s;
    animation: requestAnimation 2s infinite;
  }

  &::before {
    content: "";
    position: absolute;
    background: #303030;
    bottom: -80px;
    right: 45px;
    width: 100px;
    height: 100px;
    transform: rotate(35deg);
    border-radius: 45px;
    transition: ease-in 2s;
    animation: requestAnimation 2s infinite;
  }
}

@keyframes requestAnimation {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

.co-founder,
.our_clients,
.gallerery_shop {
  .slick-next,
  .slick-prev {
    display: none !important;
    opacity: 0;
  }
}

.our_clients,
.gallerery_shop {
  .button {
    color: rgba(255, 255, 255, 1);
    margin: 0 auto;
    font-size: 30px;
  }
}

.gallerery_shop {
  .slick-slide {
    padding: 25px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.6);
    transition: all 0.4s ease-in-out;
  }

  .slick-slide,
  .slick-slide[aria-hidden="true"]:not(.slick-cloned)
    ~ .slick-cloned[aria-hidden="true"] {
    transform: scale(0.6, 0.6);
    transition: all 0.4s ease-in-out;
  }

  /* Active center slide (You can change anything here for cenetr slide)*/
  .slick-center,
  .slick-slide[aria-hidden="true"]:not([tabindex="-1"])
    + .slick-cloned[aria-hidden="true"] {
    transform: scale(1.1);
    margin: 0 auto;
  }

  .slick-current.slick-active {
    transform: scale(1.1);
  }
  .button {
    z-index: 5;
    padding: 1rem;
    @media (max-width: 624px) {
      padding: 0px;
    }
  }
}

.background__gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../assets/img/AboutGradient.webp);
  background-color: rgba(255, 255, 255, 0.2);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.4;
}

.modal__close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 38px;
  height: 38px;
  cursor: pointer;
  background: url(../assets/img/close_modal.webp) no-repeat center;
  background-size: contain;
  z-index: 15;
}

.br_text {
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
}
