.main-animation {
  animation: mainHome 3s ease;
  &__text {
    animation: mainHomeText 3s ease;
  }
}

@keyframes mainHome {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    display: none;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
  }
}

@keyframes mainHomeText {
  0% {
    opacity: 1;
    transform: translateY(20px);
  }
  60% {
    opacity: 1;
    display: none;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
